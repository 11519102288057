@use "sass:color" {}
@use "sass:list" {}
@use "sass:meta" {}
@use "sass:string" {}
@use "./constants" as * {}
@import 'shared';

.ReportsView {
  display: flex;
  flex-direction: column;

  .leftColumn {
    min-width: 670px;
    flex: 0;
    border-right: 1px solid #ccc;
    & > section {
      padding: 2rem;
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      h3 {
        min-width: 14ch;
        flex: 0; }
      section {
        flex: 1; }
      &.submit {
        justify-content: center;
        button {
          margin: 0 1rem; } } } }
  .rightColumn {
    flex: 2; } }

.SearchView {
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  overflow: auto;
  .json {
    flex: 1;
    min-width: 20%;
    max-width: 20%;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
    padding: 2px;
    pre {
      border: none;
      border-left: 4px solid #f36d33;
      min-height: 100%; } } }


.bulkDeleteBreakdown {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 1em;
      margin: 1em 0;
      border-radius: 3px;
      background: $light-grey;
      ul {
        li {
          margin: 0; } } } } }

.statsTable {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 1em;
      margin: 1em 0;
      border-radius: 3px;
      background: $light-grey;
      ul {
        li {
          margin: 0; } } } }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%; }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px; }
  //tr:nth-child(even)
  //  background-color: #d4d2cd
  td.code {
    text-align: right; }
  td.duration {
    text-align: right; } }

.lockTable {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 1em;
      margin: 1em 0;
      border-radius: 3px;
      background: $light-grey;
      ul {
        li {
          margin: 0; } } } }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%; }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px; }
  //tr:nth-child(even)
  //  background-color: #d4d2cd
  td.code {
    text-align: right; }
  td.duration {
    text-align: right; } }

div[disabled] {
  opacity: .8; }

.EmployeesTable {
  .rdt_Table {}
  .rdt_TableRow {}
  .rdt_TableCol {}
  .rdt_TableCol_Sortable {}
  .rdt_TableCell {}
  .rdt_TableFooter {}
  .rdt_TableHead {}
  .rdt_TableHeadRow {}
  .rdt_TableBody {}
  .rdt_ExpanderRow {} }

.EmployeesTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  header {
    max-height: 66px; }
  header + div {
    flex: 1;
    display: block;
    position: relative;
    overflow: hidden; }
  .rdt_Table {
    display: flex;
    flex-direction: column; }
  .rdt_TableHeader {
    & div:nth-child(3) {
      z-index: 3;
      & > div {
 } } }        // border: 3px solid blue
  .rdt_TableHead {
    flex: 0;
    border-bottom: 1px solid #ccc; }
  .rdt_TableBody {
    flex: 1;
    max-height: auto;
    height: 100%; } }

.ContextActions {
  min-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ActionItem {
    margin-left: 1rem;
    &.Groups {
      width: 200px; } }
  .ActionItem {} }


.hoverPointer:hover {
  cursor: pointer; }

pre {
  width: 100%;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: .9em;
  line-height: 1.6;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word; }

.COLORS_1 {
  $yellow: #F6E27F;
  $green: #4E937A;
  $blue: #4281A4;
  $grey: #6A7062;
  $dark: #0E3B43;
  $timeEntry: $yellow;
  $shift: $blue; }

$yellow: #EDD382;
$green: #06A77D;
$blue: #006494;
$grey: #6A7062;
$lightDark: lighten($grey, 50%);
$dark: #262626;
$primary: #083D77;
$timeEntry: $yellow;
$shift: $blue;
$red: red;
$orange: #f36d33;
$brown: brown;
$amber: #EEB313;

$color-main: #F1F2F6;
$color-primary: #DADEE1;
$color-secondary: #ADB2B8;
$color-submain: #8A929B;

.LeaveBalanceSelectOption {
  display: inline-block;
  flex-direction: row;
  span {
    padding: 0px 3px;
    margin: 0px; }
  .leaveBalance {}
  .leaveLabel {
    padding-left: 1em;
    text-align: center; }
  .payTypeDescription {
    padding-left: 1em;
    text-align: center; }
  .payTypeCode {
    background: lightgreen;
    border: 1px solid $grey;
    border-radius: 3px;
    padding-left: 1em;
    text-align: center; } }

.PayTypeSelectOption {
  display: inline-block;
  flex-direction: row;
  span {
    padding: 0px 3px;
    margin: 0px; }
  .leaveBalance {}
  .payTypeLabel {
    padding-left: 1em;
    text-align: center; }
  .payTypeDescription {
    padding-left: 1em;
    text-align: center; }
  .payTypeCode {
    background: lightgreen;
    border: 1px solid $grey;
    border-radius: 3px;
    padding-left: 1em;
    text-align: center; } }

.UserSettings {
  EmployeeNotificationTable {
    tr, th, .textCenter {
      text-align: center; } }

  .EmployeeLeaveTable {
    tr, th, .textCenter {
      text-align: center; } }

  .emailInput {
    max-width: 300px; }
  button[role="tab"] {
    background: #666;
    padding: 4px;
    svg {
      fill: white;
      stroke: white; }
    h4 {
      font-weight: 400;
      color: #fff; } } }


.StatusIcons {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  // align-content: stretch
  // flex-basis: auto
  // flex-wrap: nowrap
  .statusIcon {
    display: block;
    flex: 0;
    .stat {
      color: $color-secondary;
      opacity: 1;
      padding: 4px;
      border-radius: 100%;
      &.on {
        background: $dark; }
      &.ot {}
      &.pto {
        background: $orange; }
      &.available {
        background: $dark; }
      &.bidded {
        background: $blue; }
      &.mybidded {
        background: $green; }
      &.sick {
        background: $brown; }
      &.jde {
        color: white;
        background: $amber; }
      &.jde.synced {
        background: $green; }
      &.approved {
        background: #4dacff; }
      &.rejected {
        background: $red; }
      &.submitted-notes {
        background: $red; }
      &.submitted {
        background: $blue; }
      &.processed {
        background: $green; }
      &.collision {
        background: $red; }
      &.acting {
        background: #2E8B57; }
      &.payPeriodLock {
        background: #d6350d; }
      &.shiftLock {
        background: #d6350d; }
      &.timeEntryLock {
        background: #d6350d; }
      &.payPeriodUnlock {
        background: #2E8B57; }
      &.shiftUnlock {
        background: #2E8B57; }
      &.timeEntryUnlock {
        background: #2E8B57; }
      &.locked {
        background: #d6350d; }
      &.unlocked {
        background: #2E8B57; } }
    .on {
      svg {
        color: white;
        fill: white; } } }

  // .anticon.stat
  //   opacity: .3

  // &.on
  //   padding: 6px
  //   background: #0E3B43
  //   color: white
  //   border-radius: 100%
 }  //   opacity: 1

.blueBG {
  background: $blue; }
.greenBG {
  background: $green; }
.yellowBG {
  background: $yellow; }
.primaryBG {
  background: $primary; }
.darkBG {
  background: $dark; }

.FormComponents {
  max-width: {}
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 10px;
  padding: 10px;
  section {
    display: block;
    background: white;
    border-radius: 3px;
    header {
      padding: 6px 10px;
      background: $lightDark;
      color: $grey;
      font-weight: 600; } }
  dl {
    min-width: 360px;
    margin: 0;
    display: block;
    padding: 10px 20px;
    &.returnValue {
      padding: 5px 20px;
      font-size: .8em;
      overflow: auto;
      pre span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 40ch;
        display: inline-block; } }
    // &:nth-child(even)
    //   background: transparent
 } }    // &:nth-child(odd)

.fakeButton {
  background: white;
  border: none;
  background: white;
  border: 1px solid transparent;
  border-right: 1px solid #d9d9d9 !important;
  color: darken(#ccc, 30%);
  text-shadow: none;
  box-shadow: none;
  &:hover {
    cursor: initial;
    background: white;
    color: darken(#ccc, 30%);
    border: 1px solid transparent;
    border-right: 1px solid #d9d9d9;
    text-shadow: none;
    box-shadow: none; } }

.field.inline_label {
  display: flex;
  align-items: center;
  justify-content: left;
  label {
    flex-shrink: 0;
    margin-right: 10px; }
  input {
    flex: 1; } }

.recordsPage {
  .view_title {
    // border-bottom-width: 8px
    // border-bottom-style: solid
    // &.timeEntry
    //   border-bottom-color: $timeEntry
    // &.shift
 }    //   border-bottom-color: $shift
  .view_content {
    height: 90%;
    .ReactTable {
      border: none;
      .rt-thead {
        height: 4rem; } }
    &.timeEntry {
      .ant-tabs-top-bar {
        background: $timeEntry; }
      .ReactTable .rt-thead {
        background: $timeEntry;
 }        // color: choose-contrast-color($timeEntry)
      .ReactTable .-pagination .-btn {
            color: rgba(0, 0, 0, 0.6);
            background: $timeEntry; }
      .-pagination .-btn:not([disabled]):hover {
            background: #c08f09;
            color: #fff; } }


    &.shift {
      .ant-tabs-top-bar {
        background: $shift; }
      .ReactTable .rt-thead {
        background: $shift;
        color: lighten($shift, 60%);
 }        // color: choose-contrast-color($timeEntry)
      .ReactTable .-pagination .-btn {
            color: white;
            background: $shift; }
      .-pagination .-btn:not([disabled]):hover {
            background: #1b09c0;
            color: #fff; } } } }

.versions_modal {
  ul {
    margin: 1rem 0;
    padding: 0 0 0 2rem;
 }    // list-style: none
  li {
 }    // padding: .5rem 0

  section {
    border-bottom: 2px dashed #ccc;
    padding: 0 0 1rem 0;
    margin-bottom: 1rem;
    &:first-child {
      h5 {
        font-size: 1.5rem;
        font-weight: 600; } } } }

// darkgoldenrod  darkolivegreen  chocolate   cadetblue   yellowgreen
// goldenrod
.roleBadge {
  display: block;
  position: relative;
  // bottom: -2px
  // left: 10px
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 900;
  background: goldenrod;
  border: none;
  color: white;
  letter-spacing: .5px;
  text-shadow: none;
  padding: 4px;
  line-height: .75rem;
  min-width: 8ch;
  max-width: 14ch;
  overflow: hidden;
  // text-overflow: ellipsis
  margin: 8px auto 0 auto; }

// .RecordsViewer .rt-tr-group &:hover
//   cursor: help

.status {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  background: #ccc;
  &.loading {
    background: #ccc; }
  &.green {
    background: green; }
  &.yellow {
    background: orange; }
  &.red {
    background: red; } }

.column {
  header {
    padding: 1rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500; }
  section {
    padding: 1rem; } }

section {
  &.input {
    padding: 1rem;
    & > div {
      display: inline-block;
      width: 100%;
      min-width: 20ch;
      max-width: 30ch; } }
  &.labelLeft {
    label {
      text-transform: capitalize;
      display: inline-block;
      margin-right: 1em;
      min-width: 15ch; }
    input {} } }

.sideBarFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: whitesmoke;
  border: 1px solid #ccc; }

.TriToggleLabel {
  min-width: 16ch !important; }

.TriToggle {
  $knob_height: 16px;
  $track_height: $knob_height + 2px;
  $track_width: $knob_height * 2;
  $move: $knob_height / 2;
  padding: 0;
  vertical-align: middle;
  display: block;
  // margin-left: 4px
  label {
    &::selection {
      background: transparent;
      color: inherit; }
    &:hover {
      cursor: pointer; } }
  // .label
  //   margin: 0 0 0 3px
  //   font-size: 10px
  //   &::selection
  //     background: transparent
  //     color: inherit
  //   &:hover
  //     cursor: pointer
  .knob {
    padding: 0;
    width: $knob_height;
    height: $knob_height;
    display: inline-block;
    border-radius: $knob_height;
    border: 1px solid #ccc;
    background: white;
    transition: transform .3s; }
  .track {
    display: inline-block;
    padding: 0;
    opacity: 1;
    text-align: center;
    background: whitesmoke;
    border-radius: $track_height;
    border: 1px solid #ccc;
    width: $track_width;
    height: $track_height;
    vertical-align: middle;
    transition: background .5s ease-out;
    &:hover {
      cursor: pointer; }
    &.include {
      background: green;
      .knob {
        transform: translateX($move); } }
    &.exclude {
      background: red;
      .knob {
        transform: translateX($move * -1); } }
    &.off {
      opacity: .8; } } }

//.FixedColumn
//    position: sticky
//    left: 0
//    z-index: 2

.RecordsViewer {
  overflow: hidden;
  .Sider {
    border-right: 1px solid #ccc;
    p {
      padding: 1rem; } }
  .Content {
    overflow-x: scroll !important;
    .info {
      display: inline-block;
      width: 20px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-pages {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-total {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .pagination {
      display: inline-block;
      position: fixed;
      bottom: 35px;
      width: 100%;
      background: white;
      border: 1px solid #ccc;
      padding: 1rem;
      box-sizing: border-box;
      z-index: 2; }
    .ReactTable {
      //overflow-x: scroll !important
      width: fit-content;
      min-width: 100%;
      .rt-tbody {
        bottom: 56px; }
      .rt-th {
        text-transform: capitalize; }
      .rt-tbody .rt-td {
        justify-content: center; }
      .pagination-bottom {
        position: absolute;
        bottom: 0;
        width: 100%; } } }
  .ContentV2 {
    overflow-x: scroll !important;
    .info {
      display: inline-block;
      width: 20px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-pages {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-total {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .pagination {
      display: inline-block;
      position: fixed;
      bottom: 35px;
      width: 100%;
      background: white;
      border: 1px solid #ccc;
      padding: 1rem;
      box-sizing: border-box;
      z-index: 2; }
    .ReactTable {
      //overflow-x: scroll !important
      width: fit-content;
      min-width: 100%;
      .rt-tbody {
        bottom: 56px; }
      .rt-th {
        text-transform: capitalize; }
      .rt-tbody .rt-td {
        justify-content: center; }
      .pagination-bottom {
        position: absolute;
        bottom: 0;
        width: 100%; } } } }
.reportTab {
  background: #ffff; }

.tabColumn {
  background: white;
  position: relative;
  .recordsInfo {
    text-align: right; }

  .ant-select {
    width: 100%;
    max-width: 256px; }
  .modelSelector {
    // position: absolute
    // to&: 4px
    // left: 1rem
    // z-index: 999
    width: 230px;
    margin-right: 1rem; }
  section {
    border-bottom: #ccc;
    h4 {
      padding: 1rem;
      background: whitesmoke; }
    &>div {
      padding: 1rem; }
    li {
      padding: 0;
      border-bottom: 1px solid #ccc; }
    .inputs {
      padding: 1rem; }
    .input {
      margin: 0;
      padding: .5rem 0;
      display: flex;
      align-items: center;
      label {
        min-width: 14ch;
        text-transform: capitalize;
        &.wide {
          min-width: 20ch; }
        &::selection {
          background: transparent;
          color: inherit; } }
      &:last-child {
        margin-bottom: 0; } } } }

.unstyledList {
  padding: 0;
  list-style: none; }

.psrs-select {
  width: 100%;
  // min-width: 200px
  display: inline-block;
  // height: 30px
  text-align: left;
  input {
    height: auto; }
  .psrs__control {
    min-height: 30px;
    .psrs__value-container {
      padding: 0 8px;
      .psrs__placeholder {}
      .psrs__input {} }
    .psrs__indicators {
      .psrs__indicator-separator {
        margin: 0; }
      .psrs__indicator {
        padding: 5px; }
      .psrs__dropdown-indicator {} } }

  .psrs__menu {
    z-index: 30;
    .psrs__menu-list {
      .psrs__option {} } } }

.DurationSelect {
  min-width: 120px; }

.DurationField {
  .success.focus {
    outline: none;
    border: 3px solid red; } }

.ResourceSelect {
  &.PayPeriodRangeSelect {
    display: inline-block;
    width: 50%;
    max-width: 160px;
    min-width: auto;
    margin: 0 .5rem; } }

.BlankPage {
  .loggingYouIn {
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
    top: 10%;
    h1 {
      font-size: 4rem;
      color: white;
      margin-bottom: 1em; }
    p {
      font-weight: 100;
      font-size: 2rem;
      opacity: .4; } } }


.-btn-timeEntry {
      color: rgba(0, 0, 0, 0.6);
      background: $timeEntry; }
.-btn-timeEntry:not([disabled]):hover {
      background: #c08f09;
      color: #fff; }

.-btn-shift {
      color: white;
      background: $shift; }
.-btn-shift:not([disabled]):hover {
      background: #1b09c0;
      color: #fff; }

/* Base styling*/
/* */
//body
//  background-color: lightgrey
//  max-width: 768px
//  margin: 0 auto
//  padding: 1em 0
//  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif

/* legend */
dl dt {
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: middle; }

dl dd {
    display: inline-block;
    margin: 0px 10px;
    padding-bottom: 0;
    vertical-align: middle; }

dl dt.ot {
    background: black;
    opacity: .5; }

dl dt.pto {
  background: $orange;
  opacity: 1; }

dl dt.regular {
  background: OliveDrab; }

dl dt.sick {
    background: $red;
    opacity: 1; }

.TabContainer {
  overflow-x: scroll !important;
  overflow-y: scroll !important; }

.tab-content {
  /* this is where you would scroll only the content */
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  //overflow:auto
  /* need some kind of height - 100% doesn't work */
  /*if you have a height like 600px and your resoltion is smaller then content gets cut off */
  height: calc(100vh - 42px); }

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  //margin-bottom: 24px
  overflow: hidden;
  background: #ce1919;
  border: 0px;
  border-radius: 2px; }

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec; }

// pop-out-table
.pwrap {
  width: 100%;
  overflow-y: "scroll" !important;
  height: "400px";
  table {
    width: 100%;
    table-layout: fixed; }
  tbody tr td {
    padding: 5px;
    border: 1px solid #eee;
    width: 100%;
    word-wrap: break-word; }
  table.head thead tr th {
    background: #eee; }
  inner_table {
    height: 100%;
    overflow-y: auto !important; } }

.cat-color-box {
  text-align: center;
  height: 2px;
  width: 2px;
  color: #fff;
  padding: 10px;
  margin: 0; }

.box {
  float: left;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .2); }

.box-shift-drawer {
  float: left;
  width: 20px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 3px; }

.blue {
  background: #13b4ff; }

.purple {
  background: #ab3fdd; }

.wine {
  background: #ae163e; }

/* future */
.audit-menu {
  font-size: 14px;
  background-color: rgb(228, 17, 17);
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 rgb(43, 6, 6);
  opacity: 1;
  transition: opacity 0.5s linear; }

// shift templates
.new-shift-error-label {
  font-weight: bold;
  color: rgb(228, 17, 17); }

.new-shift-label {
  font-weight: bold;
  color: #3c3c3c; }

.new-shift-evidence {
  font-weight: normal;
  color: #3c3c3c; }

.ShiftTemplates {
  overflow: hidden;
  .Content {
    overflow-x: scroll !important;
    .info {
      display: inline-block;
      width: 20px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-pages {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .info-total {
      display: inline-block;
      width: 150px;
      //height: 100px
      padding: 0px;
      //border: 1px solid blue
 }      //background-color: yellow
    .pagination {
      display: inline-block;
      position: fixed;
      bottom: 35px;
      width: 100%;
      background: white;
      border: 1px solid #ccc;
      padding: 1rem;
      box-sizing: border-box;
      z-index: 2; }
    .ReactTable {
      //overflow-x: scroll !important
      width: fit-content;
      min-width: 100%;
      .rt-tbody {
        bottom: 56px; }
      .rt-th {
        text-transform: capitalize; }
      .rt-tbody .rt-td {
        justify-content: left; }
      .pagination-bottom {
        position: absolute;
        bottom: 0;
        width: 100%; } } } }

.NewShiftDrawer {
  overflow: scroll !important;
  width: 100%;
  height: 100%;
  .Content {
    overflow-x: scroll !important;
    width: 100%;
    height: 100%; } }

////// need this for new approvalsV2
//.rt-tr[data-qnt="0"] > .rt-expandable
//   visibility: hidden

//// don't think we need this one
//.rt-tr[data-qnt="1"] > .rt-expandable
//  background:  aliceBlue


