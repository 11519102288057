.ShiftDrawer {
  .timeEntryTotals {
    font-weight: 600;
    .equal {
      color: green;
    }
    .error {
      color: red;

    }
  }

  .title {
    position: relative;
    .addLeave {
      position: absolute;
      right: 25px;
      top: 10px;
    }
    .createTimeEntryButton {
      position: absolute;
      right: 25px;
      top: 15px;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .durationField {
    width: 160px;
  }
  .employeeSelect {
    width: 100%;
  }
  // .supervisorSelect,
  // .equipmentSelect,
  // .businessUnitSelect,
  // .assignmentSelect,
  // .subLedgerSelect,
  // .tagsSelect {
  .psrs-select {
    flex: 1 1 auto;
  }
  // }
  // .startTimeField {}
  // .endTimeField {}
  // .workedSelect {}
  // .sickSelect {}
  // .approveSelect {}
  // .rejectSelect {}
  .timeEntries {
    & > div {
      width: 100%;
    }
  }
  .shiftActions {
    justify-content: center;
    button {
      width: 44px;
      &.link {
        width: auto;
        margin: 1rem;
      }
    }
  }
  .reasons {
    justify-content: start;
    & > div {
      flex: 1;
    }
    p {
      label {
        display: inline-block;
        width: auto;
      }
    }
  }
  section {
    position: relative;
    padding: 1rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    border-top: 1px solid lighten(whitesmoke, 1%);
    border-bottom: 1px solid darken(whitesmoke, 1%);
    &.timeEntries {
      padding: 0;
      // background: magenta;
      .ant-table-wrapper {
        padding: 0;
        margin: 0;
      }
      th {
        padding: 0.25em;
      }
      td {
        padding: 0.5em 0.25em;
      }
    }
    h4 {
      margin-bottom: 0.5em;
      font-weight: 100;
      text-transform: capitalize;
    }
    label {
      margin-right: 1rem;
      width: 100%;
      display: block;
      // vertical-align: middle;
      text-transform: uppercase;
    }
    .ant-input-number {
      width: 100%;
    }
    &.newShift {
      div {
        vertical-align: middle;
      }
      .dateTimePicker {
        display: inline-block;
        width: 400px;
      }
      .durationField {
        display: inline-block;
        width: 200px;
      }
    }
    &.shift_actions {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: black;
      background: whitesmoke;
      padding: 2em;
      flex-direction: row-reverse;
    }
  }
}
