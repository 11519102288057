$drawerZ: 5;
// new bigger size fore acting role
$drawerWidth: 100vh; //1085px

// original size
//$drawerWidth: 900px

.Drawer {
  position: absolute;
  top: 0;
  right: 0;
  &.open {
    display: block; }
  &.closed {
    display: none; }
  .DrawerContent {
    position: absolute;
    right: 0;
    background: white;
    z-index: $drawerZ;
    height: 100vh;
    width: $drawerWidth;
    //overflow-x: hidden
    // when drawer was smaller to make time entries scroll
    overflow-x: scroll !important;
    overflow-y: auto;
    box-shadow: 0 0px 18px 0px rgba(0, 0, 0, 0.4); }


  .DrawerLoading {
    z-index: $drawerZ + 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(black, .5);
    display: none;
    &.loading {
      display: block; } }

  .DrawerClose {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    font-size: 1.5em;
    opacity: .3;
    &:hover {
      cursor: pointer;
      opacity: 1; } }

  .DrawerTitle {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    padding: 2rem;
    text-transform: uppercase; }

  .DrawerMask {
    z-index: $drawerZ - 1;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(black, .5);
    &:hover {
      cursor: pointer; } } }


