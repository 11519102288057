.CalendarDay,
.CalendarDay:active {
  // border: none !important;
  // background: none !important;
}

.CalendarDay:hover {
  // background: whitesmoke;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  // border: none;
}

.CalendarDay {
  span {
    line-height: 36px;
    display: block;

    // height: 100%;
    // width: 100%;

    &.destroy_date {
      color: white;
      background: crimson;
      // border: 1px solid crimson;
    }

    &.scheduled_date {
      color: white;
      background: green;
    }

    &.schedule_date {
      color: white;
      background: #2196f3;
    }

    &.empty_date {
      display: inline;
      color: inherit;
      background: none;
      line-height: inherit;
    }
  }

  &.CalendarDay__outside {
    span {
      background: none !important;
      color: inherit !important;
      display: inline;
      line-height: inherit;
    }
  }

}

.CalendarDay__outside {
  color: #ccc;
}

.DayPickerNavigation__verticalDefault {
  height: auto;
  border: none;
  box-shadow: none;
}

.DayPickerNavigation_button__verticalDefault {
  background: darken(white, 4%);
  box-shadow: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid rgba(black, .5);
}

.CalendarDay__selected {
  background: magenta;
  color: white;
}

.CalendarDay__selected:hover {
  background: #2196f3;
  color: #fff;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.CalendarDay {
  &.CalendarDay__default {}

  &.CalendarDay__highlighted_calendar {
    background: rgba(#2196f3, 1);
    color: white;
  }
}

// =============================================================

.DayPicker {
  &.DayPicker_portal__vertical {}

  height: -webkit-fill-available;

  .DayPicker_weekHeaders {
    .DayPicker_weekHeader {
      margin-left: -150px;
      padding: 0px 13px;

      &.DayPicker_weekHeader__vertical {}

      ul.DayPicker_weekHeader_ul {
        li.DayPicker_weekHeader_li {
          small {}
        }
      }
    }
  }

  .DayPicker_focusRegion {


    .DayPickerNavigation {
      &.DayPickerNavigation__vertical {}

      &.DayPickerNavigation__verticalDefault {}

      .DayPickerNavigation_button {
        &.DayPickerNavigation_button__vertical {}

        &.DayPickerNavigation_button__verticalDefault {}

        &.DayPickerNavigation_prevButton__verticalDefault {}

        &.DayPickerNavigation_nextButton__verticalDefault {}

        svg.DayPickerNavigation_svg__vertical {}
      }
    }

    .DayPicker_transitionContainer {


      &.DayPicker_transitionContainer__vertical {}

      .CalendarMonthGrid {
        // transform: translateY(0px);
        width: 300px;

        &.CalendarMonthGrid__vertical {}

        .CalendarMonthGrid_month__hideForAnimation {
          &.CalendarMonthGrid_month__hideForAnimation_1 {}

          &.CalendarMonthGrid_month__hidden {}

          .CalendarMonth {
            &.CalendarMonth_1 {}

            .CalendarMonth_caption {}

            table.CalendarMonth_table {
              tbody {
                tr {
                  td {}
                }
              }
            }
          }
        }
      }
    }
  }
}