.EmployeeSelect,
.EmployeeSelectDropdown {
    color: inherit;
    .employee_option {
        display: flex;
        max-width: 300px;
        div {
            padding: 0.5rem 1rem;
            border-right: 1px solid rgba(black, 0.05);
            line-height: 2rem;
            &.job {
                flex: 1;
                min-width: 90px;
                text-align: right;
                padding: 4px 0 3px 0;
                &.after {
                    margin-left: 4px;
                    min-width: auto;
                    text-align: left;
                }
            }
            &.name {
                flex: 3;
                width: 220px;
            }
            &.specialty_pills {
                max-width: 120px;
                flex: 1;
            }
            &.home_business_unit {
                flex: 3;
                display: none;
            }
        }
    }
}

.EmployeesSelectDropdown {
    color: inherit;
    .employee_option {
        display: flex;
        div {
            padding: 1rem;
            border-right: 1px solid rgba(black, 0.05);
            line-height: 1.7rem;
        }
        .job {
            flex: 1;
            min-width: 90px;
            text-align: right;
        }
        .name {
            flex: 3;
            width: 220px;
        }
        .specialty_pills {
            flex: 2;
        }
        .home_business_unit {
            flex: 3;
            display: none;
        }
    }
}