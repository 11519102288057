.specialty_pills {
  padding: 0.5em 0;
}

.pill {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 1.7em;
  padding: 0 0.5em;
  background: lighten(black, 20%);
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 0.3em;
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8ch;
  vertical-align: middle;
  border: 1px solid;
  text-shadow: 0 -1px 0px rgba(black, 0.5);
}
