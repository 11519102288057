@import './Default/Normalize.scss';
@import './Default/Defaults.scss';
@import './Default/StyleHelpers.scss';

@import "../vendor/dhtmlx/dhtmlxscheduler.css";
@import './Packages/antd.scss';
@import './Packages/react-dates.scss';
@import './Packages/react-table.scss';

@import './Elements/Form.scss';
@import './Elements/Modal.scss';
@import './Elements/Pill.scss';
@import './Elements/Table.scss';
@import './Elements/Drawer.sass';

@import './Forms/AssignmentSelect.scss';
@import './Forms/BusinessUnitSelect.scss';
@import './Forms/CheckBox.scss';
@import './Forms/DurationField.scss';
@import './Forms/EmployeeSelect.scss';
@import './Forms/EndTimeField.scss';
@import './Forms/EquipmentSelect.scss';
@import './Forms/InlineEdit.scss';
@import './Forms/JobPill.scss';
@import './Forms/PayTypeSelect.sass';
@import './Forms/ShortDaySelect.scss';
@import './Forms/StartTimeField.scss';
@import './Forms/SupervisorFilter.scss';
@import './Forms/SupervisorSelect.scss';
@import './Forms/TagSelect.scss';
@import './Forms/Selects.sass';

@import './Components/PowerView.sass';
@import './Components/Layout.sass';
// @import './Components/Approvals.scss';
// @import './Components/Assignments.scss';
// @import './Components/BusinessUnits.scss';
// @import './Components/Certifications.scss';
@import './Components/Employee.sass';
@import './Components/Employees.scss';
// @import './Components/Gizmos.scss';
@import './Components/Login.scss';
@import './Components/PayTypes.scss';
// @import './Components/Reasons.scss';
@import './Components/Settings.scss';
@import './Components/ShiftDrawer.scss';
// @import './Components/Specialties.scss';
// @import './Components/Tags.scss';
@import './Components/Template.sass';
@import './Components/Templates.scss';
@import './Components/Timeline.sass';
@import './Components/Team.sass';
@import './Components/AppNav.sass';

// @import './Components/Trainings.scss';
// @import './react-table.scss';
// @import './react-dates.scss';
// @import './ant.scss';
// @import './tables.scss';
// @import './forms.scss';
// @import './_modals.scss';
// @import './layout.scss';
// @import './login.scss';
// @import './templates.scss';
// @import './scheduler.scss';
// @import './pills.scss';
// @import './approvals.scss';
// @import './settings.scss';
// @import './employees.scss';
@import './Components.sass';
@import './Components/Print.sass';

@import './Default/Print.scss';
@import './Components/WeekDayPicker.sass';

@import './UI.sass';

@import './Components/Footer.scss';