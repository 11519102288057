.schedulePage {
  .command {
    .supervisor {
      display: flex;
      h5 {
        display: inline-block;
        min-width: 100px; } } } }

.ClickMask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;
  background: rgba(0,0,0, .3);
  // background: magenta
  display: none;
  &.open {
    display: block; }
  &:hover {
    cursor: pointer; } }

.defaultWeekDays {
  h4, div {
    display: inline-block;
    vertical-align: middle; }
  h4 {
    margin-right: 1rem; }
  .WeekDayPicker {
    width: 30ch !important; } }


.rosterTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 75px;
  tr {
    border-top: 1px solid #ccc;
    &.loading {
      opacity: .4; } }
  td {
    padding: 1em;
    vertical-align: middle;
    width: auto;
    &.colCount {
      width: 60px;
      text-align: center; }
    &.colEmployee {
      width: auto; }
    &.colAccounting {
      width: 300px; }
    &.colDates {
      width: 230px; }
    &.colShortDays {
      width: 260px; }
    &.colTime {
      width: 180px; }
    &.colActions {
      width: 120px; } } }

.ant-layout-content {
  position: relative; }

.ant-fullcalendar-date.disabled *:hover,
.ant-fullcalendar-date.disabled:hover {
  cursor: no-drop; }

.disabledTemplate {
  display: block;
  padding: 1rem;
  width: 100%;
  background: red;
  border-bottom: 1px solid white;
  text-align: center;
  color: white;
  font-weight: 400; }

.Template.disabled {
  .ant-fullcalendar-date:hover,
  .WeekDayPicker .week div:hover,
  .daysOfTheWeek div:hover,
  .ant-fullcalendar-month-panel-cell:hover,
  .slotActions:hover,
  .slotActions i:hover,
  .slotActions svg:hover, {
    cursor: no-drop; } }

.Template {
  .daysOfTheWeek {
    .firstWeekDays {
      margin-top: 10px; }
    .secondWeekDays {
      margin-top: 10px; } }

  .calendarHeader {
    .datesButton {}
    .monthsButton {}
    .month {
      width: 150px;
      text-align: center;
      .prev, .next {
        display: inline-block;
        height: 24px;
        line-height: 30px; }
      .prev {
        float: left; }
      .next {
        float: right; }
      .title {
        margin: 0;
        display: inline-block; } } }

  .PayPeriodRangeSelector {
    display: flex;
    justify-content: space-between;
    .payPeriodStart, .payPeriodEnd {
      flex: 1; } }

  footer {
    background: whitesmoke;
    border-top: 1px solid #ccc;
    width: 100%;
    padding: 2rem;
    position: fixed;
    bottom: 0;
    right: 0;
    text-align: right;
    z-index: 1;
    button {
      margin-left: 1rem; }
    // left: 0
    // right: 0
    .datesToSchedule {
      display: inline-block; } }
  .calendar_info {
    padding: 1rem !important;
    text-align: center;
    // border-top: 1px solid rgba(0, 0, 0, 0.05) !important
    background: whitesmoke !important; }

  .ant-fullcalendar-today .ant-fullcalendar-value {
    border: 1px dashed #1890ff;
    box-shadow: none; }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65);
    background: white;
    box-shadow: none; }

  .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
  .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65);
    background: white;
    box-shadow: none; }

  // .ant-fullcalendar-selected-day .ant-fullcalendar-value
  //   color: rgba(0, 0, 0, 0.65)
  //   background: white

  .ant-fullcalendar-selected-day .ant-fullcalendar-value,

  .ant-fullcalendar-month,
  .ant-fullcalendar-date {
    background-color: white;
    .fullcalendar-value:hover {
      cursor: pointer; }
    &.schedule .ant-fullcalendar-value {
      box-shadow: 0 0 0 1px #1890ff inset;
      background: #e6f7ff !important;
      border: none;
      color: rgba(0, 0, 0, 0.65); }
    &.scheduled .ant-fullcalendar-value {
      background-color: green !important;
      color: white !important;
      border: none; }
    &.destroy .ant-fullcalendar-value {
      background-color: red !important;
      color: white !important;
      border: none; } }

  section {
    background: #fff;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    header {
      padding: 1rem;
      background: lighten(#000, 70%); }
    & > div {
      padding: 1rem 2rem; } }
  .grid_container {
    border-top: 1px solid #ccc;
    & > * {
      justify-self: stretch; }
    color: inherit;
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 20% 20% 20% 40%;
    grid-template-rows: auto auto;
    // grid-template-areas: "team supervisor shift calendar" "groups command shift calendar" "businessUnit command shift calendar" "roster roster roster roster"
    grid-template-areas: "columnA columnB columnC columnD" "roster roster roster roster";
    justify-items: stretch;
    align-items: stretch;
    align-content: start;
    height: 100vh;

    .grid_section {
      align-self: start;
      height: 100%;
      &.columnA {
        grid-area: columnA; }
      &.columnB {
        grid-area: columnB; }
      &.columnC {
        grid-area: columnC; }
      &.columnD {
        grid-area: columnD; }
      &.calendar {
        & > div {
          padding: 0; }
        .ant-fullcalendar-year-select {
          width: 80px; }
        .ant-fullcalendar-month-select {
          width: 80px; } }
      &.roster {
        grid-area: roster;
        border-right: none;
        & > div {
          padding: 0; } } } } }
