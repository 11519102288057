.PayTypes {
  color: inherit;

  .payTypeCategory {
    width: 200px;

    &>div {
      width: 100%;
    }
  }
}