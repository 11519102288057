$white: #ffffff;
$red: #FB3640;
$yellow: #FAA916;
$green: #4D8B31;
$blue: #0094C6;
$grey: #6A7062;
$light-grey: lighten($grey, 50%);
$dark-grey: darken($grey, 5%);
$dark: #0E3B43;
$timeEntry: $yellow;
$shift: $blue;

$toolbarHeight: 44px;
$top_h: 44px;
$bottom_h: 44px;
$title_h: 37px;
$AppNavHeight: 50px;
$TitleBarHeight: 44px;
$minSectionHeight: 44px;
