// #33ccff #22aadd #2f8eca #264d68 #0d233e #010103
// #d73027 #f46d43 #fdae61 #fee090 #e0f3f8 #abd9e9 #74add1 #4575b4
// #ffffd9 #edf8b1 #c7e9b4 #7fcdbb #41b6c4 #1d91c0 #225ea8 #0c2c84
// E5E7E6 78B0D9 226F92 797F92 232B3C
// EFF1F2 699ABF 0076AC 5E6C76 1F2A34

* {
  outline: none !important;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  min-height: 100vh;
  position: relative;
}

#root {
  background: #262933;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
  z-index: 99;
}

a[role=button] {
  text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

.quiet {
  opacity: .4;
}

dl {
  display: block;
  margin: 2px;
}

.ant-dropdown li div.selected {
  color: #ccc;
}

blockquote {
  text-align: center;
  display: block;
  padding: 2rem;
  margin: 1rem 0;
  background: whitesmoke;
  border: 1px solid darken(whitesmoke, 10%);
  border-radius: 4px;
  width: 100%;
}

.dim {
  opacity: .4;
}

.alignLeft {
  text-align: left;

  div {
    text-align: left;
    justify-content: left !important;
  }
}

.typeIcon {
  svg {
    opacity: .3;
  }
}