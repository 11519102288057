$light: #EFF1F2;
$lighter: #699ABF;
$primary: #0076AC;
$medium: #5E6C76;
$dark: #1F2A34;

$bg_a: #ffffd9;
$bg_b: #edf8b1;
$bg_c: #c7e9b4;
$bg_d: #7fcdbb;
$bg_e: #41b6c4;
$bg_f: #1d91c0;
$bg_g: #225ea8;
$bg_h: #0c2c84;

$approved: green;
$xnew: #84828F;
$new: OliveDrab;
$worked: $bg_f;
$confirmed: $bg_g;
$available: #ccc;


.ScheduleTimeline {
  height: 100vh; }


.TimelinePage {
  color: inherit;
  height: 100vh;
  #scheduler_target {
    flex: 1;
    display: flex; }
  .view_title {
    flex: 0;
    display: block; }
  .timelineZoom {
    text-align: right;
    div {
      position: relative !important; } }
  .timelineDate {
    z-index: 0; }
  .ant-row {
    position: relative;
    top: 0;
    height: 100%; }
  .ant-col {
    position: relative;
    top: 0;
    height: 100%; }
  .dhx_cal_navline .relative {
    position: relative; } }

.dhx_cal_data {
  flex: 1;
  height: auto; }
.GroupSelector {
  margin-right: 20px; }
.payPeriodSelector {
  // display: none
  position: relative !important;
  top: 0 !important;
  padding: 6px 20px;
  div {
    position: relative;
    top: 0 !important; }
  h5 {
    margin: 0 0 3px 0; } }
.react_select_container.members {
  min-width: 300px; }
.dhx_cal_tab {
  display: none; }
.ant-drawer-content-wrapper {
  overflow: visible;
  overflow-y: auto;
  height: 1vh;
  .ant-drawer-content {
    overflow: visible;
    overflow-y: scroll;
    overflow-x: visible !important;
    height: 100%;
    .ant-drawer-wrapper-body {
      overflow: visible !important;
      height: 100%;
      .ant-drawer-header {
        .ant-drawer-title {} }
      .ant-drawer-body {
        overflow: visible !important;
        .shift_drawer_form {
          overflow: visible !important; } } } } }
.dhx_matrix_scell {
  &.item {
    vertical-align: middle;
    &>div {}
    .dhx_scell_name {
      line-height: 1.6em;
      vertical-align: middle;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: 50%; } } }
.time_scale {
  flex: 0;
  min-width: 300px; }
.dhx_cal_event_line {
  position: absolut; }
.dhx_matrix_cell, .dhx_matrix_scell {
  border-right: 1px solid rgba(#000, .05); }
.dhx_event_resize {
  height: 21px;
  // &.dhx_event_resize_start
 }  // &.dhx_event_resize_end
.dhx_cal_event_line .dhx_event_resize {
  cursor: w-resize;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  width: 4px;
  height: 100; }
.dhx_scell_name {
  font-size: 1.2em;
  font-weight: bol; }
.dhx_event_resize {
  display: non; }
.dhx_cal_event_line {
  border: 4px solid #fff;
  border-radius: 8px;
  padding: 0px;
  overflow: visible;
  position: relative;
  &.new {
    --bg-color: $new; }
  &.worked {
    --bg-color: $worked; }
  &.available {
    --bg-color: $available; }
  &.approved {
    --bg-color: green; }
  &.sick {
    --bg-color: red;
    opacity: 1; }
  &.pto {
    --bg-color: #f36d33;
    opacity: 1; }
  &.ot {
    --bg-color: black;
    opacity: .5; }
  .shift_bar {
    border-radius: 4px;
    background: var(--bg-color, $new);
    padding: 6px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    span {
      flex: 1; }
    .left_meta, .right_meta {
      color: black; //words
      padding: 0 .5em;
      position: absolute;
      top: 2px;
      height: 100%;
      span {
        display: inline-block; } }
    .left_meta {
      right: 100%;
      text-align: right;
      min-width: 15ch; }
    .right_meta {
      left: 100%;
      text-align: left;
      min-width: 10ch; } }
  .name {
    font-weight: 300;
    font-size: 1.1em;
    border-radius: 3px;
    text-transform: uppercase;
    min-width: 22ch;
    .first {
      margin: 0; }
    .last {
      margin: 0;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .bu_code {}
    margin: 0 0 .5em 0; }
  .assignment, .duration {
    background: #fff;
    $duration_color: darken(#ccc, 50%);
    text-align: center;
    padding: 3px 4px;
    border: 1px solid rgba($duration_color, .5);
    border-radius: 4px;
    flex: 0;
    color: $duration_color;
    b {
      text-transform: uppercase; } }
  .pto {
    background: #fff;
    text-align: center;
    padding: 3px 4px;
    border: 1px solid orange;
    border-radius: 4px;
    flex: 0;
    color: black;
    b {
      text-transform: uppercase; } }
  .sick {
    background: #fff;
    text-align: center;
    padding: 3px 4px;
    border: 1px solid red;
    border-radius: 4px;
    flex: 0;
    color: black;
    b {
      text-transform: uppercase; } }
  .ot {
    background: #fff;
    text-align: center;
    padding: 3px 4px;
    border: 1px solid black;
    border-radius: 4px;
    flex: 0;
    color: black;
    b {
      text-transform: uppercase; } }
  .short_early, .short_late {
    background: #fff;
    text-align: center;
    padding: 3px 4px;
    border: 1px solid black;
    border-radius: 4px;
    flex: 0;
    color: black;
    b {
      text-transform: uppercase; } }
  .duration {
    margin: 0; }
  &.scale_20 {
    .left_meta {
      display: none; } }
  &.scale_20, &.scale_30 {
    .name .first {
      display: none; } }
  &.scale_50 {
    .job_title {
      white-space: nowrap; } } }
.dhx_timeline_label_row {
  .dhx_matrix_scell.item {
    padding: 0;
    .dhx_scell_level0 {
      text-align: left;
      padding: 0;
      .dhx_scell_name {
        padding: .5em; } } } }
.last-truncate {
  margin: 0;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // .dhx_cal_navline
  //   .payPeriodSelector
  //     h5
  //     .PayPeriodNav
  //   .day_tab
  //   .week_tab
  //   .timeline_tab.active
  //   .month_tab
  //   .timelineDate
  //   .timelineZoom
  //   .dhx_cal_prev_button
  //   .dhx_cal_next_button
  //   .dhx_cal_today_button
  // .dhx_cal_header
  // .dhx_second_cal_header
  //   .dhx_scale_bar
  //   .dhx_second_scale_bar
  //   .dhx_bottom_scale_container
  //   .dhx_scale_bar
  // .dhx_cal_data
  //   .dhx_timeline_table_wrapper
  //     .dhx_timeline_label_wrapper
  //       .dhx_timeline_label_col
  //         .dhx_timeline_label_row
  //           .dhx_matrix_scell
  //     .dhx_timeline_data_wrapper
  //     .dhx_timeline_scrollable_data
  //       .dhx_timeline_data_col
  //         .dhx_matrix_line
  //           .dhx_timeline_data_row
  //             .dhx_matrix_cell.dhx_timeline_data_cell
  // .dhx_timeline_scale_header
 }  // .dhx_timeline_second_scale
