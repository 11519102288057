/* TableStyles.module.css */
.tableContainer {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: scroll;
    border: 1px solid #ddd;
}

.tableCustom {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    position: relative;
    /*border: 1px solid #ddd;*/
}

.thCustom {
    background: #E6E6E6;
    position: sticky;
    z-index: 1;
    top: 0;
    /*box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);*/
}

.tdCustom {
    /*border: 1px solid #000;*/
    padding: 8px;
    text-align: left;
}

.trEven {
    background-color: #f2f2f2;
}
