.PayPeriodNav {
  margin: 0 1rem; }

.ColumnToggleSelect {
  ul {
    max-height: 500px !important;
    height: fit-content; } }

.actions {
  button {
    margin-left: .5em;
    span {} }
  .ant-btn-group {
    margin-left: .5em;
    button {
      margin: 0; } } }

.power_drawer {
  flex: 1 0 auto;
  transition: width .3s;
  overflow: hidden;

  .ant-btn-group {
    .ant-btn {
      border-color: #ccc; }
    .ant-btn:focus {
      outline: none;
      box-shadow: none;
      border-color: #ccc;
      color: inherit; }
    .ant-btn:first-child {
      border-right: none; }
    .ant-btn:last-child {
      border-left: none; } }

  .ant-btn-group.active, .ant-btn-group:hover {

    &.red .ant-btn {
      border-color: red;
      color: red; }
    &.green .ant-btn {
      border-color: green;
      color: green; } }

  &.left_side {
    border-right: 1px solid lighten(#000, 90%); }
  &.right_side {
    border-left: 1px solid lighten(#000, 90%); }
  &.open {
    width: 200px; }
  &.closed {
    width: 0; }
  section {
    border-bottom: 1px solid lighten(#000, 90%);
    padding: 1.3rem;
    header {
      display: block;
      position: relative;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      margin-bottom: .5em;
      text-align: center;
      .ant-badge-count {
        background: lighten(#000, 40%); } }
    h5 {
      text-align: center;
      text-transform: uppercase; }

    button {
      margin: .25rem 0;
 }      // width: 100%
    p {
      text-align: center;
      font-size: 1rem; } }

  .update_values {
    button {
      margin-top: 1rem; }
    .ant-select-auto-complete {
      margin-top: 1rem;
      width: 100%; } }

  .bulk_actions {
    button {
      text-align: left; } } }
