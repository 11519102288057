/**
ReactTable Overrides
 */

$cell-padding: 7px 5px;

.ReactTable {
  background: #fff;

  .inlineEdit {
    border-color: rgba(black, .06);
  }

  .row_actions {
    text-align: right;
    display: block;
    width: 100%;
    padding: 0 1rem;

    button {
      margin: 0 1em;
    }

    a {
      margin: 0 1em;
    }

  }

  .id_column {
    width: 10rem;
  }

  .rt-tbody .rt-td {
    padding: $cell-padding;
    display: flex;
    align-items: center;
    white-space: normal;
    overflow: visible;
  }

  .rt-thead.-header {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .-pagination {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .rt-thead .rt-th {
    padding: $cell-padding;
  }

  .rt-thead .rt-resizable-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .rt-thead.-filters .rt-th {
    padding: 16px 8px;
  }

  .rt-thead.-filters input,
  .rt-thead.-filters select {
    border: none;
    background: initial;
    padding: 6px 0 7px 0;
  }

  .-pagination .-center {
    order: 0;
    justify-content: flex-start;
  }

  .-pagination .-previous,
  .-pagination .-next {
    max-width: 64px;
  }

  .-pagination .-previous {
    order: 1;
  }

  .-pagination .-next {
    order: 2;
  }
}