.ant-btn {
  &.suspended:hover {
    cursor: initial;
    border-color: rgb(217, 217, 217);
    color: rgba(0, 0, 0, 0.65);
    &.ant-btn-primary {
      color: #fff;
      background-color: #1890ff;
      border-color: transparent; } } }


header {
  position: relative; }

.Help {
  // position: absolute
  // top: 13px
  margin: 0 1rem 0 0;
  opacity: .3;
  transition: opacity .6s;
  &:hover {
    cursor: pointer;
    opacity: 1; }
  &.right {
 } }    // right: 1rem
