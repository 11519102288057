#audit.table-container {
    max-height: 500px; /* Adjust as needed */
    overflow-y: scroll;
    overflow-x: scroll;
    border: 1px solid #ddd; /* Optional: for a nice border */
}

#audit {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    position: relative;
    border: 1px solid #ddd;
}

#audit th {
    background: #E6E6E6;
    position: sticky;
    z-index: 1;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
#audit th, #audit td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}


#audit tr:nth-child(even){background-color: #f2f2f2}

#loadingIndicator {
    display: none; /* Hide loading indicator by default */
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    line-height: 400px;
    color: #000;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    line-height: 400px;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#container{
    margin:0 auto;
    width:300px;
    position:relative;
    outline:2px solid #060;
}
#container #click{
    width:80px;
    line-height:36px;
    background-color:#090;
    color:white;
    text-align:center;
    position:absolute;
    right:0;
    top:0;
    cursor: pointer;
}
#container input[type="text"]{
    width:298px;
    height:30px;
    font-size:medium;
    padding:right;
}
