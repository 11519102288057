.form_modal_title {
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  height: 6rem;
  line-height: 6rem;
  color: #fff;
  background: #1890ff;
}

.form_modal {
  position: relative;
  padding: 0 !important;
  max-height: 95vh;
  min-width: 470px;
  max-width: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 50px;
}