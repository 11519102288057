.ReportsView {
  @keyframes spinner {
    to {
      transform: rotate(360deg); } }
  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 55%;
    left: 66%;
    width: 50px;
    height: 50px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite; } }
