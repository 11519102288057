.GridBlock {
  display: grid;
  column-gap: .5rem;
  row-gap: .5rem; }

.FlexBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0;
  &.flex-start {}
  &.flex-end {}
  &.space-between {}
  &.space-around {}
  &.space-evenly {}
  &.flex-start {}
  &.flex-end {}
  &.v-middle {}
  &.v-baseline {}
  &.v-top {}
  &.v-bottom {}
  section {
    border: none;
    padding: 0; }
  //   *
  //     color: magenta
  //   &:first-child *
  //     color: blue
  //   &:last-child *
 }  //     color: green

.Box {
  h4 {
    font-weight: 100;
    text-transform: capitalize; } }

.flex0 {
  flex: 0; }
.flex1 {
  flex: 1; }
.flex2 {
  flex: 2; }
