.WeekDayPicker {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 !important;
  max-width: 30ch;
  &.disabled {
    opacity: .8;
    .week div:hover {
      cursor: no-drop; } }
  .week {
    display: flex;
    &.firstWeek {
      border-bottom: 1px solid #ccc; }
    div {
      flex: 1 1 auto;
      text-align: center;
      padding: 3px;
      border-left: 1px solid #ccc;
      font-size: .8em;
      max-width: 40px;
      &:first-child {
        border: none; }
      &:hover {
        cursor: pointer; }
      &.off {
        background: white; }
      &.on {
        background: #1890ff;
        color: white; }
      &.label {
        background: whiteSmoke;
        text-transform: uppercase;
        width: 30px;
        &:hover {
          cursor: pointer; } } } } }
