.Settings {
  color: inherit;
}

.settings_form {
  section {
    border-bottom: 1px solid #ccc;
    margin: 1rem 0;
    padding: 2rem 0;

    span {
      display: inline-block;
    }

    label {
      display: inline-block;
      margin-right: 1rem;
      min-width: 200px;
    }

    .react-tel-input {
      display: inline-block;
      width: 300px;
    }

    input {
      display: inline-block;
      width: 300px;
    }

    .end {
      margin-left: 2rem;
    }
  }
}