.ReactTable {

  //overflow-x: scroll !important ;
  //overflow-y: scroll !important ;
  //width: fit-content;


  .rt-thead {
    padding-right: 12px;
    background: lighten(black, 90%);

    &.-filters {
      display: none;
    }

    .rt-th {

      border-bottom: 1px solid lighten(black, 70%);
    }
  }

  .rt-tbody {
    z-index: 3;

    //.rt-td {
      // flex-direction: column;
      // justify-content: center;
    //}
  }

  .checkbox {
    label {
      display: block;
      width: 100%;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  /** new stuff */
  .-pagination {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

  .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 95%;
    height: 95%;
    border: 0;
    border-radius: 3px;
    padding: 1px;
    font-size: .8em;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(166, 201, 12, 0.1);
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    cursor: pointer;
    outline: none;
  }

  
  .-pagination .-btn[disabled] {
    opacity: .7;
    cursor: default
  }

  .-pagination .-btn:not([disabled]):hover {
    background: #1b09c0;
    color: #fff
  }

  .-pagination .-previous,
  .-pagination .-next {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    
  }

  .-pagination .-center {
    -webkit-box-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    //justify-content: space-evenly
  }

  .-pagination .-pageInfo {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap
  }

  .-pagination .-pageJump {
    display: inline-block;
  }

  .-pagination .-pageJump input {
    width: 70px;
    text-align: center
  }

  .-pagination .-pageSizeOptions {
    margin: 3px 10px
  }
  /** end new */

}

.column_align_center {
  justify-content: center;
  max-width: 10ch;
}

/**
Basic Table Styles
 */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;

  thead {
    tr {
      th {
        padding: 16px 8px;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        white-space: nowrap;
      }

      th:first-child {
        padding-left: 24px;
      }

      th:last-child {
        padding-right: 24px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 16px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      td:first-child {
        padding-left: 24px;
      }

      td:last-child {
        padding-right: 24px;
      }

      &:last-child td {
        border-bottom: none;
      }
    }
  }

  &.clickable tbody tr {
    cursor: pointer;
  }

  &.clickable tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}


.short_day {
  span {
    margin-right: 1ch;
  }

  .every {
    color: #ccc;
  }

  .day {
    text-transform: uppercase;
  }

  .shift {
    color: #ccc;
  }
}

//.logo {
//  height: 32px;
//  margin: 16px;
//  background: rgba(255, 255, 255, 0.3);
//}

.trigger {
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
  font-size: 24px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.version-bullet {
  list-style: inside;
  list-style-image: url("../../svg/bullet.png");
}