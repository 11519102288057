.ant-btn {
  vertical-align: middle;
}

ul.ant-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;

  li.ant-dropdown-menu-item-group {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.ant-dropdown-menu-item-group-title {
  text-transform: uppercase;
  text-align: center;
  background: whitesmoke;
  border-bottom: 1px solid rgba(#000, .2);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-top: -4px;
}

.ant-badge {
  z-index: 3;
}

.ant-tooltip,
.ant-select-dropdown {
  z-index: 2000;
}

// .ant-list-item {
//   padding: 0;
// }

.ant-list-bordered {
  .ant-list-item {
    position: relative;
    padding: 1rem;
  }
}

.ant-list-item-content {
  /* position: relateive; */
}

ul.ant-menu-root {
  // overflow: auto;
  // max-height: 100%;
}

.ant-menu {}

.ant-menu-dark {}

.ant-menu-root {}

.ant-menu-inline {}

.ant-menu-inline-collapsed {
  // width: 3rem;
}

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #F6F6F6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.certain-category-search.ant-select-focused .certain-category-icon {
  color: #108ee9;
}

.certain-category-icon {
  color: #6E6E6E;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}