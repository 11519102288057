/* autoprefixer grid: no-autoplace */

$top_bar_height: 64px;
$sidenav_width: 80px;
$sidenav_bg_color: lighten(#000, 12%);
$sidenav_bg: $sidenav_bg_color;
$sidenav_icon_color: rgba(white, .4);
$sidenav_icon_hover: white;
$app_layout_height: 100vh;
$app_container_height: auto;
$app_body_height: auto;
$app_body_width: auto;
$table_th_height: 40px;
$table_pagination_height: 40px;


.Version {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: 9; }

.clientVersion {
  width: 60px;
  text-align: center;
  position: fixed;
  bottom: 23px;
  left: 10px;
  font-size: 1rem;
  color: #fff;
  font-weight: normal;
  opacity: .8;
  padding: 6px;
  border-radius: 6px;
  background: black;
  border: 1px solid rgba(white, 40%);
  &:hover {
    cursor: pointer;
    opacity: 1; } }

.approvalBadge {
  margin: 0 10px; }

.SideBarNav {
  z-index: 9;
  background: #001529;
  width: 40px;
  position: relative;
  i {
    vertical-align: middle; }

  svg {
    width: auto;
    height: 26px;
    color: $sidenav_icon_color;
    fill: $sidenav_icon_color;
    polygon,
    path {
      fill: $sidenav_icon_color; } }

  .nav_items {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      // height: 60px
      text-align: center;
      margin: 0;
      display: block;
      position: relative;
      z-index: 9;
      .link {
        padding: 16px 0;
        display: block;
        &.active {
          background: #1890ff;
          svg, polygon, path {
            color: white;
            fill: white; } } }
      &:hover {
        background: black;
        svg {
          color: white;
          fill: white; }
        polygon,
        path {
          fill: white; }
        .itemName {
          display: inline-block; } } }
    &.lower {
      position: absolute;
      bottom: 60px; } }
  .ant-badge {
    position: absolute;
    top: 0;
    right: 0; }

  .itemName {
    display: none;
    position: absolute;
    left: 105%;
    background: #001529;
    color: white;
    padding: 6px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 99; }

  .logo {
    background: rgba(#000, .5);
    height: $top_bar_height;
    padding: 10px;
    color: #fff;
    span {
      display: inline-block;
      font-size: 2rem;
      font-weight: 600;
      vertical-align: middle; }
    svg {
      display: inline-block;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-right: 10px; } } }


.Breadcrumb {
  padding: .5em 1.5em;
  margin: 0;
  position: relative;
  .actionRight {
    position: absolute;
    right: 10px;
    top: 6px; }

  span:hover {
    cursor: pointer; } }



.AppContent {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .view_title {
    .center {
      flex: 0; } }

  .view_title_schedule {
    white-space: nowrap;
    width: 100%;
    flex: 0 0 auto;
    border-bottom: 1px solid #ccc;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    //height: $top_bar_height
    height: auto;
    background: #fff; }

  .view_title,
  .page_header {
    // added overflow-y/x width, whitepace to scroll virtical
    overflow-y: auto !important;
    overfow-x: none;
    white-space: nowrap;
    width: 100%;
    flex: 0 0 auto;
    border-bottom: 1px solid #ccc;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    height: $top_bar_height;
    background: #fff;

    .page_column_filters {
      label {
        margin-left: 1rem; } }



    .actions {
      .toggle {
        margin-right: 8px; } }



    h1 {
      small {
        margin-left: 20px;
        opacity: .4; } } }




  .view_content {
    flex: 1;
    display: flex;
    flex-direction: row;

    // &>div
    //   width: 100%
 } }    //




.island {
  margin: 2rem;
  padding: 2rem 3rem;
  background: #fff; }


.mask,
.bg_dim {
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all .06s ease;

  &.true {
    opacity: .4;
    width: 100vw;
    height: 100vh; } }



.mask {
  z-index: 3; }


.animated-page-wrapper {
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100%; }


.ReactTable {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // position: absolute
  // top: 0
  // right: 0
  // bottom: $table_pagination_height
  // left: 0
  // width: 100%

  .rt-table {
    flex: 0 1 auto;
    overflow: hidden; }


  .rt-head {
    height: $table_th_height;
    flex: 0 0 auto; }

  .rt-tbody {
    flex: 1 1 auto;
    position: absolute;
    top: $table_th_height;
    left: 0;
    right: 0;
    bottom: $table_pagination_height;
    z-index: 2; }

  .pagination-bottom {
    flex: 0 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $table_pagination_height;

    .-pagination {
      background: white; } } }
