.Employees {
  color: inherit;
}

.pb {
  p {
    border: 1px solid #ccc;
    padding: 1em;
  }

  b {
    text-align: right;
    display: inline-block;
    width: 17ch;
    margin-right: 1ch;

    &:after {
      content: ':';
      display: inline;
    }
  }
}