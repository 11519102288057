.Footer {
  color: inherit
}
    
  
 p.footer {
    background-color: black;
    color: lightgray
}
p.footer:first-letter { border-left: 1em solid black;}
