.Team {
  section {
    background: #fff;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    header {
      padding: 1rem;
      background: lighten(#000, 70%); }
    & > div {
      padding: 2rem;
      &.item {
        padding: 1rem 2rem; } } }
  .grid_container {
    background: white;
    & > * {
      justify-self: stretch; }
    color: inherit;
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: auto auto auto;
    grid-template-rows:  auto auto;
    // grid-template-areas: "team supervisor shift calendar" "groups command shift calendar" "businessUnit command shift calendar" "members members members members"
    grid-template-areas: "one two three";
    justify-items: stretch;
    align-items: stretch;
    align-content: start;
    height: 100vh;
    width: 100%;
    .grid_section {
      align-self: start;
      height: 100%;
      &.name {
        grid-area: one; }
      &.creator {
        grid-area: one; }
      &.command {
        grid-area: one; }
      &.groups {
        grid-area: one; }
      &.two {
        grid-area: two;
        border-right: none;
        & > div {
          padding: 0; } }
      &.three {
        grid-area: three;
        ul {
          padding: 0;
          list-style: none; }
        li {
          display: flex;
          justify-content: space-between;
          padding: .5em 1em;
          .link {
            flex: 1; }
          .icon {
            flex: 0; } } } } } }

