$break-width: 1700px;

.Templates {
  color: inherit;
}

.loading {
  width: 100%;
  height: 100%;
  // border: 4px solid magenta;

  .ant-spin-container {
    // border: 4px solid blue;
    height: 100%;
  }
}

.DayPicker_weekHeader_li:hover {
  cursor: pointer;
  font-weight: bold;
}