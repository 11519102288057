.ant-tooltip {
  @media print {
    display: none !important; } }

.noPrint {
  @media print {
    display: none; } }

.clientVersion {
  @media print {
    display: none; } }

.Print {
  $padding: 3pt 6pt;
  $font: 9pt;

  @mixin font {
    font-size: $font !important; }
  @mixin padding {
    padding: $padding; }

  height: 100vh;

  span, tr, td, div {
    @include font; }

  .supervisorCard {
    position: relative;
    padding: 1rem;
    margin-top: 1rem;
    .field {
      margin: 5px 0; } }

  .addSupervisor {
    margin-left: 1rem; }

  .removeSupervisor {
    position: absolute;
    top: -4px;
    right: -4px; }

  .title {
    text-transform: uppercase;
    margin-right: 1ch;
    font-size: 7pt !important; }

  .shiftRow {
    @include padding; }

  .toolbar {
    $width: 400px;
    min-width: $width !important;
    max-width: $width !important;
    width: $width !important;
    flex: 0 0 $width !important;
    padding: 2rem;
    background: whitesmoke;
    border-right: 1px solid #ccc;
    h3 {
      color: inherit;
      margin-bottom: 2rem; }
    section {
      margin: .5rem 0;
      border-top: 1px solid rgba(white, .4);
      padding: 2rem 0;
      header {
        font-size: 1.1em;
        text-transform: uppercase;
        color: inherit; } }


    @media print {
      display: none; } }



  .teamCard {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: 5pt 0;
    .ant-card-head {
      padding: 0;
      min-height: auto; }
    .ant-card-head-title {
      @include padding; } }

  .printTable table {
    tbody {
      tr {
        td {
          @include padding; } } } }


  .ant-card-body {
    padding: 0; }
  .content {
    background: whitesmoke;
    padding: 2rem;
    @media print {
      padding: 0;
      background: white; } }
  .page {
    background: #fff;
    box-shadow: 1px 1px 4px rgba(0,0,0,.5);
    height: 96vh;
    width: calc(96vh * .77);
    padding: 10pt;

    @media print {
      box-shadow: none;
      padding: 0; } } }
