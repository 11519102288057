.pulse {
    animation-name: pulse_animation;
    animation-duration: 2000ms;
    transform-origin:70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    //background: #5a99d4;
    display: block;
    box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
    //color: white;
    //border: none;
    //width: 100px;
    //height: 30px;
    //border-radius: 8px;
    //background-image: (linear-gradient(to right, #2d8fe5, #d155b8));
}

.pulse:hover {
    animation-name: none;
  }

@keyframes pulse_animation {
    0% { 
        transform: scale(.9);
        background-color: #5a99d4;
 
    }
    70% { 
        transform: scale(1); 
        background-color: #0d82f0;
        box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
    100% { 
        transform: scale(.9); 
        background-color: #5a99d4;
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}
@keyframes glowing {
0% {
    background-color: #5a99d4;
    box-shadow: 0 0 3px #5a99d4;
}
50% {
    background-color: #284561;
    box-shadow: 0 0 15px #284561;
}
100% {
    background-color: #5a99d4;
    box-shadow: 0 0 3px #5a99d4;
}
}