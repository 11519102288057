.TagSelect,
.DateSelector,
.GroupsSelector,
.PayTypeCategoryFilter,
.ColumnToggleSelect,
.EquipmentSelect,
.SpecialtySelect,
.SubLedgerSelect,
.EmployeesSelect,
.AssignmentSelect,
.PayTypeSelect,
.LeaveBalanceSelect,
.EmployeeSelect {
  width: 100%;
  max-width: 100%; }

.SupervisorFilter {
  width: 300px; }

.SubLedgerSelectDropdown,
.PayTypeSelectDropdown,
.EmployeesSelectDropdown,
.EmployeeSelectDropdown {
  width: auto !important;
  min-width: 400px !important;
  min-height: 300px !important; }
