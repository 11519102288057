$select_height: 2.3em;
$button_width: 2.3em;
$border_color: hsl(0, 0%, 80%);
$blue: #2196f3;

.ant-btn {
  &.needsApproved {
    background: #f5222d;
    border-color: #f5222d;
    color: #fff;
  }
}

.reason_category_select {
  min-width: 140px;
}

.reason_category_option {
  min-width: 100%;
}


.select_list {
  .select_option_renderer:nth-last-of-type(2n) {
    background: whitesmoke;
  }

  .select_option_renderer {
    .react_select__option {
      height: 100%;
      padding: 1ch;

      .select_option {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.option_select {
  .select_option:nth-of-type(2n) {
    background: whitesmoke;
  }

  padding: 1ch;
}

.option_flex {
  margin: -8px auto;
  display: flex;
  align-items: center;

  div {
    flex: 1;
    display: inline-block;
    padding: 2ch;

    &:hover {
      cursor: pointer;
    }
  }

  .code {
    max-width: 6ch;
    text-align: right;
    vertical-align: middle;
    display: inline-block;
  }

  .name {
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: none;
    line-height: 1.4em;
  }

  .category {
    max-width: 13ch;
  }
}


.inlineEdit {
  padding: .5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

form label {
  z-index: 2;
}

input[type=text] {
  border: 1px solid #ccc;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

label.label_above {
  display: block;
  margin-bottom: 1rem;
}

label.label_to_left {
  display: inline-block;
}

.form_row {
  padding: 2rem 0;
}

.field_with_errors {

  input,
  .ant-input-number {
    min-width: 100%;
  }

  &.error {

    input,
    .ant-input-number {
      border-color: red;
    }

    p {
      color: red;
      font-size: .8em;
    }
  }
}