.LeaveBalanceSelect, .PayTypeSelect {
  &.noName {
    min-width: 240px; }

  .ant-select-selection-selected-value {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    padding-right: 10px;
    .code {
      flex: 0; }
    .description {
      flex: 1; }
    .category {
      flex: 0; } } }

.PayTypeOption {
  display: flex;
  justify-content: space-between;
  .code {
    flex: 0; }
  .description {
    flex: 1; }
  .category {
    flex: 0; } }


.PayTypeSelect {
  li {
    font-weight: 200 !important;
    &.ant-select-dropdown-menu-item-selected {
      background: #1890ff;
      color: #fff; } }
  .option_flex {
    .code {
      min-width: 8ch;
      flex: 1; }
    .name {
      flex: 3; }
    .category {
      min-width: 10ch;
      flex: 1;
      color: inherit; } } }
