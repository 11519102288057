.Login {
  color: inherit;
}

.session_layout {}

.session_container {}

.session_body {}

.login_page {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: linear-gradient(-180deg, #03AAF5 0%, #01579C 100%);

  .paper {
    padding: 2rem;
    width: 30rem;
  }
}

.login_logo {
  width: 10rem;
  height: 10rem;
  margin: 6rem;
}

.login_page svg path {
  fill: #fff;
}

.login_page .MuiPaper-root-1 {
  width: 40rem;
  padding: 2rem;
  margin: 5rem;
}