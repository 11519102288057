.AppNav {
  display: block;
  min-width: 300px;
  background: rgba(white, 50%);
  padding: 3rem;
  flex: 0;

  section {
    margin-bottom: 1rem; }

  h4 {
    margin-bottom: 1rem; }

  ul {
    margin-bottom: 2rem;
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    li {
      margin: 1rem; } }


  .svgButtons {
    $size: 60px;
    a {
      display: block;
      color: #001529; }
    svg {
      width: auto;
      height: 100%;
      max-width: 100%;
      display: inline-block;
      path {
        fill: white; } }
    span {
      display: inline-block;
      vertical-align: middle;
      &.icon {
        width: $size;
        height: $size;
        padding: 12px;
        border-radius: 100%;
        background: #001529;
        vertical-align: middle; }
      &.title {
        margin-left: 1rem;
        text-transform: capitalize; } } } }
